import type { SubmissionResult } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod'
import { createMailjetContact } from '@fuse/core/mailjet'
import type { SEOHandle } from '@nasa-gcn/remix-seo'
import {
  type ActionFunctionArgs,
  type LinksFunction,
  json,
} from '@remix-run/node'
import { z } from 'zod'
import { About } from './about'
import heroServiceDesktop from './assets/hero-service-desktop.jpg'
import heroServiceMobile from './assets/hero-service-mobile.jpg'
import { Cta } from './cta'
import { Details } from './details'
import { FAQ } from './faq'
import { Hero } from './hero'
import { Newsletter } from './newsletter'
import { Partners } from './partners'
import { Pricing } from './pricing'

export const handle: SEOHandle = {
  getSitemapEntries: () => [{ route: '/', priority: 1 }],
}

export type LandingActionType = typeof action
export const emailSchema = z.object({
  email: z.string().email(),
})

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData()
  const submission = parseWithZod(formData, { schema: emailSchema })
  if (submission.status !== 'success') return json(submission.reply())

  await createMailjetContact({ email: submission.value.email })

  return json({ status: 'success' } as SubmissionResult)
}

export const links: LinksFunction = () => [
  { rel: 'preload', href: heroServiceDesktop, as: 'image', type: 'image/jpg' },
  { rel: 'preload', href: heroServiceMobile, as: 'image', type: 'image/jpg' },
]

export default function LandingPage() {
  return (
    <>
      <Hero />
      <Details />
      <Cta />
      <Pricing />
      <About />
      <Partners />
      <Newsletter />
      <FAQ />
    </>
  )
}
