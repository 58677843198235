import { cn } from '@/lib/utils'

export function Section({
  children,
  className,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) {
  return (
    <section className={cn('container pt-24 lg:pt-52', className)} {...props}>
      {children}
    </section>
  )
}

export function SectionHeader({ children }: { children: React.ReactNode }) {
  return (
    <header className='pb-12'>
      <h2 className='text-center text-5xl font-extrabold'>{children}</h2>
    </header>
  )
}
