import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Link } from '@remix-run/react'
import { faq } from '../_marketing.faq'
import { Section, SectionHeader } from './section'

export function FAQ() {
  return (
    <Section id='faq' className='max-w-5xl'>
      <SectionHeader>Good to know</SectionHeader>

      <Accordion type='single' collapsible className='w-full'>
        {faq.slice(0, 4).map((faq, index) => (
          <AccordionItem
            key={faq.title}
            value={`item-${index + 1}`}
            className='text-left'
          >
            <AccordionTrigger className='text-xl font-normal text-left items-start lg:items-center lg:text-center'>
              {faq.title}
            </AccordionTrigger>
            <AccordionContent className='text-xl'>
              {faq.content}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
      <p className='text-center pt-14'>
        Want to know more? Go to the full{' '}
        <Link to='/faq' className='underline'>
          FAQ
        </Link>
      </p>
    </Section>
  )
}
