import { buttonVariants } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { Link } from '@remix-run/react'

export function Cta() {
  return (
    <section className='mt-24 lg:mt-52 px-16  flex flex-col items-center text-center'>
      <p className='text-4xl lg:text-5xl leading-[43px] font-extrabold'>
        Ready to send your work like it deserves?
      </p>
      <p className='text-muted-foreground text-2xl'>Start for free.</p>

      <Link
        to='/auth/signup/'
        prefetch='intent'
        className={cn(buttonVariants({ variant: 'cta' }), 'mt-8 font-semibold')}
      >
        Start sharing now
      </Link>
    </section>
  )
}
