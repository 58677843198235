import { buttonVariants } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { Link } from '@remix-run/react'
import heroServiceDesktopUrl from './assets/hero-service-desktop.jpg'
import heroServiceMobileUrl from './assets/hero-service-mobile.jpg'

export function Hero() {
  return (
    <div className='container pt-3 sm:pt-16 flex flex-col items-center gap-16'>
      <div className='mx-auto text-center px-2 md:px-12'>
        <h1 className='text-5xl leading-1 md:text-[64px] font-extrabold lg:whitespace-nowrap tracking-tight sm:tracking-normal'>
          Share like it matters.
        </h1>

        <p className='mt-4 text-xl lg:text-2xl'>
          File sharing for creatives:
          <br className='md:hidden' /> Easy. Secure. Beautiful.{' '}
        </p>
        <Link
          to='/auth/sign-up'
          prefetch='intent'
          className={cn(
            buttonVariants({ variant: 'cta' }),
            'mt-7 font-semibold',
          )}
        >
          Start sharing now
        </Link>
      </div>

      <picture className='xl:max-w-[1280px]'>
        <source srcSet={heroServiceMobileUrl} media='(max-width:1024px)' />
        <source srcSet={heroServiceDesktopUrl} media='(min-width:1024px)' />
        <img src={heroServiceDesktopUrl} alt='Hero Service' />
      </picture>
    </div>
  )
}
