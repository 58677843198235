import aboutPosterUrl from './assets/about-poster.jpg'
import { Section, SectionHeader } from './section'

export function About() {
  return (
    <Section id='about'>
      <SectionHeader>About us</SectionHeader>

      <p className='text-2xl text-center lg:px-52 mb-16'>
        As creatives, we know how important it is to show your work at its best
        and keep it protected. We’re building the tool we always wanted as
        musicians and designers ourselves.
      </p>

      {/* biome-ignore lint/a11y/useMediaCaption: we don't have one :( */}
      <video
        src={`${import.meta.env.VITE_MARKETING_CDN_URL}/about.mp4`}
        poster={aboutPosterUrl}
        width={3840}
        height={2160}
        controls
        preload='none'
      />
    </Section>
  )
}
