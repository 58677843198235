import { Field } from '@/components/forms'
import { Icons } from '@/components/icons'
import { ModalProvider, popAllModals, pushModal } from '@/components/modals'
import { Button } from '@/components/ui/button'
import { StatusButton } from '@/components/ui/status-button'
import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod'
import { Link, useFetcher } from '@remix-run/react'
import * as React from 'react'
import { toast } from 'sonner'
import { type LandingActionType, emailSchema } from './route'
import { Section, SectionHeader } from './section'

const freeFeatures = [
  <React.Fragment key={0}>
    Up to <span>3</span> active pages
  </React.Fragment>,
  <React.Fragment key={1}>
    Up to <span>3GB</span> total storage
  </React.Fragment>,
  <React.Fragment key={2}>
    Send large media files up to <span>3GB</span>
  </React.Fragment>,
  <React.Fragment key={3}>
    Blockchain <span>ownership for every file</span>
  </React.Fragment>,
  <React.Fragment key={4}>
    <span>Lossless preview</span> of audio and pictures
  </React.Fragment>,
  <React.Fragment key={5}>
    Pages expire after <span>30 days</span>
  </React.Fragment>,
]

const creatorFeatures = [
  <React.Fragment key={0}>
    Up to <span>20</span> active pages
  </React.Fragment>,
  <React.Fragment key={1}>
    Up to <span>200GB</span> total storage
  </React.Fragment>,
  <React.Fragment key={2}>
    Send large media files up to <span>10GB</span>
  </React.Fragment>,
  <React.Fragment key={3}>
    Blockchain <span>ownership for every file</span>
  </React.Fragment>,
  <React.Fragment key={4}>
    <span>Lossless preview</span> of audio and pictures
  </React.Fragment>,
  <React.Fragment key={5}>
    Pages expire after <span>90 days</span>
  </React.Fragment>,
]

const proFeatures = [
  <React.Fragment key={0}>
    <span>Unlimited</span> pages
  </React.Fragment>,
  <React.Fragment key={1}>
    Up to <span>2TB</span> total storage
  </React.Fragment>,
  <React.Fragment key={2}>
    Send large media files up to <span>20GB</span>
  </React.Fragment>,
  <React.Fragment key={3}>
    Blockchain <span>ownership for every file</span>
  </React.Fragment>,
  <React.Fragment key={4}>
    <span>Fraunhofer watermark</span> on audio files
  </React.Fragment>,
  <React.Fragment key={5}>
    <span>Password-protected</span> pages
  </React.Fragment>,
  <React.Fragment key={6}>
    <span>Lossless preview</span> of audio and pictures
  </React.Fragment>,
  <React.Fragment key={7}>
    <span>Set custom expiry dates</span> for your pages
  </React.Fragment>,
]

export function Pricing() {
  const fetcher = useFetcher<LandingActionType>()

  React.useEffect(() => {
    if (fetcher.data?.status === 'success') {
      popAllModals()
      toast('Sign up successful!', {
        description: "You're now part of our mailing list.",
      })
    }
  }, [fetcher.data])

  const [form, fields] = useForm({
    lastResult: fetcher.data,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: emailSchema })
    },
    shouldRevalidate: 'onInput',
  })

  const onNotifyMeClick = () =>
    pushModal('ResponsiveDialog', {
      title: (
        <span className='text-primary font-bold text-3xl'>
          Be the first in line!
        </span>
      ),
      description: (
        <span className='text-primary text-xl font-normal'>
          Don't miss out on customization and branding features, more security
          with the watermark and maxed out storage. Get notified when we launch
          our new tiers.
        </span>
      ),
      content: (
        <fetcher.Form
          method='POST'
          {...getFormProps(form)}
          className='flex flex-col'
        >
          <Field
            labelProps={{
              children: 'Email',
              className: 'text-primary',
            }}
            inputProps={{
              ...getInputProps(fields.email, { type: 'email' }),
              placeholder: 'Enter your email',
            }}
          />

          <StatusButton
            status={
              fetcher.state !== 'idle'
                ? 'pending'
                : form.status === 'success'
                  ? 'success'
                  : 'idle'
            }
            variant='cta'
            type='submit'
            className='w-min'
            disabled={fetcher.state !== 'idle'}
          >
            Join mailing list
          </StatusButton>
        </fetcher.Form>
      ),
    })

  return (
    <Section id='pricing'>
      <SectionHeader>Pricing</SectionHeader>

      <div className='mx-auto max-w-lg lg:max-w-none grid grid-cols-1 lg:grid-cols-3 gap-12 lg:gap-8'>
        <PricingCard>
          <PricingCardHeader id='always-free'>Always free</PricingCardHeader>

          <PricingCardDescription>
            All the essentials for sharing and presenting securely.{' '}
          </PricingCardDescription>

          <PricingCardPrice price={0} />

          <Button asChild variant='cta'>
            <Link
              to='/auth/sign-up'
              aria-describedby='always-free'
              className='mt-6 w-full font-semibold'
            >
              Sign up
            </Link>
          </Button>

          <PricingCardFeatures features={freeFeatures} />
        </PricingCard>

        <PricingCard>
          <PricingCardHeader id='creator'>Creator</PricingCardHeader>

          <PricingCardDescription>
            More storage, more control, and expanded presentation.
          </PricingCardDescription>

          <PricingCardPrice price={9} />

          <Button
            aria-describedby='creator'
            className='mt-6 w-full'
            onClick={onNotifyMeClick}
          >
            Notify me
          </Button>

          <PricingCardFeatures features={creatorFeatures} />
        </PricingCard>

        <PricingCard>
          <PricingCardHeader id='pro'>Pro</PricingCardHeader>

          <PricingCardDescription>
            Brand your page. Full professional package with maxed-out storage
            and control.
          </PricingCardDescription>

          <PricingCardPrice price={23} />

          <Button
            aria-describedby='pro'
            className='mt-6 w-full'
            onClick={onNotifyMeClick}
          >
            Notify me
          </Button>

          <PricingCardFeatures features={proFeatures} />
        </PricingCard>
      </div>
      <ModalProvider />
    </Section>
  )
}

function PricingCard({ children }: { children: React.ReactNode }) {
  return (
    <div className='ring-1 ring-muted rounded-3xl p-8 xl:p-10'>{children}</div>
  )
}

function PricingCardHeader({
  children,
  id,
}: { children: React.ReactNode; id: string }) {
  return (
    <h3 className='text-2xl font-semibold leading-8 text-primary' id={id}>
      {children}
    </h3>
  )
}

function PricingCardDescription({ children }: { children: React.ReactNode }) {
  return (
    <p className='mt-6 text-sm leading-6 text-muted-foreground'>{children}</p>
  )
}

function PricingCardPrice({ price }: { price: number }) {
  return (
    <p className='mt-6 flex items-baseline gap-x-1'>
      <span className='text-4xl font-bold tracking-tight'>${price}</span>
      <span className='text-sm font-semibold leading-6'>/month</span>
    </p>
  )
}

function PricingCardFeatures({
  features,
}: { features: Array<React.ReactNode> }) {
  return (
    <ul className='mt-8 space-y-3 text-sm leading-6 xl:mt-10'>
      {features.map((feature, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <li key={index} className='flex gap-x-3'>
          <Icons.Check
            className='h-5 w-4 flex-none text-muted-foreground'
            aria-hidden='true'
          />

          <p className='text-muted-foreground [&>span]:text-primary'>
            {feature}
          </p>
        </li>
      ))}
    </ul>
  )
}
