import { ErrorList } from '@/components/forms'
import { Input } from '@/components/ui/input'
import { StatusButton } from '@/components/ui/status-button'
import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod'
import { Link, useFetcher } from '@remix-run/react'
import * as React from 'react'
import { toast } from 'sonner'
import { type LandingActionType, emailSchema } from './route'
import { Section, SectionHeader } from './section'

export function Newsletter() {
  const fetcher = useFetcher<LandingActionType>({ key: 'newsletter' })

  React.useEffect(() => {
    if (fetcher.data?.status === 'success') {
      toast('Sign up successful!', {
        description: "You're now part of our mailing list.",
      })
    }
  }, [fetcher.data])

  const [form, fields] = useForm({
    lastResult: fetcher.data,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: emailSchema })
    },
    shouldRevalidate: 'onInput',
  })

  return (
    <Section className='max-w-[600px]'>
      <SectionHeader>Stay updated</SectionHeader>

      <p className='text-center text-lg'>
        Stay in touch, and we'll provide occasional updates on how to improve
        and secure your creative work and collaborations.
      </p>

      <fetcher.Form
        method='POST'
        {...getFormProps(form)}
        className='mt-14 flex flex-col sm:flex-row gap-5'
      >
        <Input
          {...getInputProps(fields.email, { type: 'email' })}
          placeholder='Enter your email'
        />

        <StatusButton
          status={
            fetcher.state !== 'idle'
              ? 'pending'
              : form.status === 'success'
                ? 'success'
                : 'idle'
          }
          variant='cta'
          type='submit'
          disabled={fetcher.state !== 'idle'}
        >
          Subscribe here
        </StatusButton>
      </fetcher.Form>

      <ErrorList errors={form.errors} id={form.errorId} />

      <p className='mt-12 text-lg font-semibold text-center'>
        By subscribing, you accept our{' '}
        <Link to='/privacy' className='underline'>
          privacy policy
        </Link>
      </p>
    </Section>
  )
}
