import { buttonVariants } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { Link } from '@remix-run/react'
import audioplayerMobile from './assets/mobile-audioplayer.jpg'
import certificateMobile from './assets/mobile-certificate.jpg'
import pagesMobile from './assets/mobile-pages.jpg'
import sharepageMobile from './assets/mobile-sharepage.jpg'

const detailsArray = [
  {
    image: certificateMobile,
    title: 'Keep the receipts.',
    subTitle: "Don't get ripped off - get a proof of each upload.",
    description:
      'Every time you upload a file, you get a certificate. That record of your work is stored securely, forever.',
  },
  {
    image: sharepageMobile,
    title: 'Pitch perfect.',
    subTitle: 'Make your work stand out with fuse pages.',
    description:
      'Put everything you want to send in one place, with total customization. Send pages via link or add them to your bio.',
  },
  {
    image: pagesMobile,
    title: 'Stress-free.',
    subTitle: 'Never lose files or send dead links.',
    description:
      'All your files in one place. Upload big files and keep links active for 30 days, not three - even on the free plan.',
  },
  {
    image: audioplayerMobile,
    title: 'Love the player.',
    subTitle: 'High-quality playback for every track.',
    description:
      'Play sound files with a high-quality media player - with lossless audio.',
  },
]

export function Details() {
  return (
    <section
      id='features'
      className='container md:px-12 lg:px-48 pt-20 lg:pt-52 space-y-24 lg:space-y-52'
    >
      {detailsArray.map((detail, index) => (
        <div
          key={detail.title}
          className={cn(
            'flex flex-col-reverse lg:flex-row lg:justify-between gap-12 lg:gap-20 items-center',
            index % 2 !== 0 && 'lg:flex-row-reverse',
          )}
        >
          {index + 1 !== detailsArray.length ? (
            <Link
              to='/auth/signup/'
              prefetch='intent'
              className={cn(
                buttonVariants({ variant: 'cta' }),
                'font-semibold lg:hidden mt-4',
              )}
            >
              Start sharing now
            </Link>
          ) : null}
          <img
            src={detail.image}
            alt='screenshot of service'
            className='max-h-[630px] lg:max-h-[478px]'
          />
          <div className={cn('space-y-6 max-w-[342px] lg:max-w-[558px]')}>
            <header>
              <h2 className='text-4xl lg:text-5xl leading-[42px] lg:leading-[58px] font-extrabold pb-2 lg:pb-0 whitespace-nowrap tracking-tighter lg:tracking-normal'>
                {detail.title}
              </h2>
              <h3 className='text-3xl lg:text-4xl leading-[36px] lg:leading-[48px] font-light tracking-tighter lg:tracking-normal'>
                {detail.subTitle}
              </h3>
            </header>
            <p className='text-xl text-muted-foreground'>
              {detail.description}
            </p>
          </div>
        </div>
      ))}
    </section>
  )
}
